import React, { useState } from "react"
import { connect } from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Slider2 from "../components/slider2"
import { sendContactMessage } from "../store/actions/otherActions"
import { toast } from "react-toastify"

const ShopByCategory = ({ isLoading, sendContactMessage }) => {
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [showMoreIntroText, setMoreIntroText] = useState(false)
  const initState = () => {
    setName("")
    setPhoneNumber("")
    setEmailAddress("")
    setSubject("")
    setMessage("")
  }
  const handleSendContactMessage = e => {
    e.preventDefault()
    let contact = {
      name: name,
      phone_numbers: phoneNumber,
      email_address: emailAddress,
      subject: "OFFRE ENTREPRISE - " + subject,
      message: message,
    }
    sendContactMessage(contact)
      .then(() => {
        initState()
        toast.success(
          "Message envoyé 🚀. Nous vous contacterons très bientôt.",
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      })
      .catch(() => {})
  }
  const toggleMoreIntroText = () => {
    setMoreIntroText(!showMoreIntroText)
  }
  const pageName = "OFFRE ENTREPRISE"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-col justify-center py-6">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Offre entreprise
            </span>
            <h1 className="text-center text-2xl">
              Des vêtements à faire laver ?
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Vous êtes des personnes exceptionnelles, voilà pourquoi
              <br />
              Mon ePressing traite vos vêtements exceptionnellement.
              <br />
              <button
                className="hover:text-blue-600 focus:border-opacity-0"
                style={{ border: 0 }}
                onClick={toggleMoreIntroText}
              >
                <strong>
                  &#8811; De plus, nous offrons plusieurs avantages à vos
                  clients (*)
                </strong>
              </button>
              {showMoreIntroText && (
                <center>
                  <p style={{ textAlign: "justify", maxWidth: 500 }}>
                    <br />
                    1. Entreprise exerçant dans la vente de vêtements, literies,
                    moquettes ou tout autre domaine lié à la vente de linge,
                    nous offrons à vos clients{" "}
                    <strong>un bon de réduction de 100%</strong> sur le
                    traitement d’un article acheté chez vous. (**)
                    <br />
                    2. Entreprise exerçant dans le domaine de la Restauration
                    quand à vous, mon ePressing offre à vos clients{" "}
                    <strong>
                      des bons de réductions de 15% sur l’ensemble de vos
                      articles
                    </strong>
                    .
                    <br />
                    3. Enfin quand aux entreprises exerçant dans le domaine
                    médical ou pastoral, nous leurs offrons{" "}
                    <strong>
                      un traitement gratuit d’une blouse blanche ou tout autre
                      tenue de travail
                    </strong>
                    . N’hésitez surtout pas à nous contacter pour plus
                    d’informations.
                    <br />
                    <br />
                    (*) La seule condition requise afin de pouvoir profiter de
                    cette offre est que l’entreprise partenaire nous fournisse
                    les informations du client.
                    <br />
                    (**) L’offre est valable uniquement si le coût total de la
                    commande passé chez mon epressing est supérieur à 10.000
                    FCFA.
                  </p>
                </center>
              )}
            </span>
          </div>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap pb-4 justify-center">
            <div className="w-full md:w-2/3">
              <form className="w-full" onSubmit={handleSendContactMessage}>
                <div className="flex flex-wrap">
                  <div className="w-full pb-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-name"
                    >
                      Nom
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-name"
                      type="text"
                      onChange={e => setName(e.target.value)}
                      value={name}
                      name="name"
                      required
                    />
                  </div>
                  <div className="w-full md:w-1/2 pr-2 py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-phone-number"
                    >
                      N° de téléphone
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-phone-number"
                      type="text"
                      onChange={e => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      name="phoneNumber"
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2 py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-email-address"
                    >
                      Adresse email
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-email-address"
                      type="email"
                      onChange={e => setEmailAddress(e.target.value)}
                      required
                      name="email"
                      value={emailAddress}
                    />
                  </div>
                  <div className="w-full py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-subject"
                    >
                      Sujet
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-subject"
                      type="text"
                      name="subject"
                      onChange={e => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                  <div className="w-full py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-message"
                    >
                      Message
                    </label>
                    <textarea
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name="message"
                      id="grid-message"
                      rows="7"
                      onChange={e => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                  </div>
                  {isLoading && (
                    <div
                      className="w-full bg-blue-100 border border-blue-400 text-blue-700 py-3 rounded relative text-center"
                      role="alert"
                    >
                      <span className="block sm:inline">
                        Chargement en cours ...
                      </span>
                    </div>
                  )}
                  <div className="w-full py-2">
                    {isLoading ? (
                      <span className="text-white bg-blue-200 border-blue-600 hover:bg-blue-800 hover:border-blue-800 font-semibold py-3 px-4 rounded text-center shadow-lg text-xs">
                        Envoyer mon message
                      </span>
                    ) : (
                      <button
                        type="submit"
                        className="text-white bg-blue-600 border-blue-600 hover:bg-blue-800 hover:border-blue-800 font-semibold py-2 px-4 rounded text-center shadow-lg text-xs"
                      >
                        Envoyer mon message
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.other.isLoading,
  }
}

const mapDispatchToProps = {
  sendContactMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopByCategory)
