import axios from "axios"

import {
  SEND_CONTACT_MESSAGE_LOADING,
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_FAIL,
} from "./types"

import { BASE_URL } from "../../utils/urls"

export const sendContactMessage = contact => (dispatch, getState) => {
  dispatch({ type: SEND_CONTACT_MESSAGE_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/api/others/contact", contact)
      .then(res => {
        dispatch({ type: SEND_CONTACT_MESSAGE_SUCCESS, payload: res.data })
        resolve(res.data)
      })
      .catch(err => {
        console.log("Error 2", err.response ? err.response.data : err)
        dispatch({
          type: SEND_CONTACT_MESSAGE_FAIL,
          payload: err.response ? err.response.data : err,
        })
        reject(err.response ? err.response.data : err)
      })
  })
}
